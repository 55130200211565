import storage from 'utils/storage';
import log from 'utils/log';

const REJECTION_EMAIL_PREFS_KEY = 'rejectionEmailPreferences';

interface OrganizationPreferences {
  enabled: boolean;
}

interface AllPreferences {
  [organizationPk: string]: OrganizationPreferences;
}

const readPreferences = (): AllPreferences => {
  const allPreferences = storage.get(REJECTION_EMAIL_PREFS_KEY);
  return typeof allPreferences === 'string' ? JSON.parse(allPreferences) : {};
};

const writePreferences = (preferences: AllPreferences): void => {
  storage.persist({ [REJECTION_EMAIL_PREFS_KEY]: JSON.stringify(preferences) });
};

const getOrganizationPreferences = (organizationPk: string): boolean => {
  try {
    const parsed = readPreferences();
    return parsed[organizationPk]?.enabled ?? true; // default to opt-in
  } catch (error) {
    log(`Failed to parse preferences for org ${organizationPk}:`, error);
    return true;
  }
};

const setOrganizationPreferences = (
  organizationPk: string,
  enabled: boolean,
): void => {
  try {
    const parsed = readPreferences();
    parsed[organizationPk] = { enabled };
    writePreferences(parsed);
  } catch (error) {
    log(`Failed to save preferences for org ${organizationPk}:`, error);
  }
};

export const getRejectionEmailPreferences = (organizationPk: string): boolean =>
  getOrganizationPreferences(organizationPk);

export const updateRejectionEmailEnabled = (
  organizationPk: string,
  enabled: boolean,
): void => setOrganizationPreferences(organizationPk, enabled);
