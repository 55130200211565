import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import Button from 'components/Form/Button';
import SelectFilters from 'components/SelectFilters';
import MultiFilterInput from 'components/SelectFilters/MultiFilterInput';

import createActiveFiltersArray from 'utils/createActiveFiltersArray.js';
import { useLocation } from 'react-router';
import ActiveFilterTag from './ActiveFilterTag';
import styles from './index.less';

function CustomFilters({
  filterSets,
  values,
  onChange,
  onClearAllFilters,
  areFiltersShown,
  relatedUsers,
  jobPositions,
  allStageLists,
  ratingScales,
}) {
  const { t } = useTranslation();
  const location = useLocation();

  const wrapperRef = useRef(null);
  const activeFiltersArray = createActiveFiltersArray({
    location,
    relatedUsers,
    jobPositions,
    allStageLists,
    ratingScales,
    t,
  });

  const handleFilterChange = (filter) => (selected) => {
    const newValue = selected === null ? [] : selected; // Convert `null` to an empty array
    onChange(filter, newValue);
  };

  // helper function that checks if there are any active filters
  const hasActiveFilters = () =>
    Object.entries(values).some(
      ([key, value]) =>
        (Array.isArray(value) && value.length > 0) ||
        // check values for single select filter with boolean value
        value === 'true' ||
        value === 'false' ||
        // check for rejection status filter
        (key === 'rejectionStatusFilter' &&
          value !== null &&
          value !== undefined),
    );

  const customFieldsFilters = filterSets.filter(
    (o) => o.categoryId.indexOf('customFieldFilter') === 0,
  );

  const customFieldsSelectedChoicesFilters = filterSets.filter(
    (o) => o.categoryId.indexOf('customFieldMultichoiceFilter') === 0,
  );

  return (
    <div>
      <div
        ref={wrapperRef}
        data-testid="custom-filters-menu"
        tabIndex="-1"
        className={styles.filtersWrapper}
      >
        {areFiltersShown && (
          <div className={styles.filtersBar}>
            {filterSets.map((o) => {
              if (
                o.categoryId.includes('customFieldFilter') ||
                o.categoryId.includes('customFieldMultichoiceFilter')
              ) {
                return null;
              }

              return (
                <div key={o.categoryId} className={styles.filter}>
                  <SelectFilters
                    mode={
                      o.categoryId !== 'hasCvFilter' &&
                      o.categoryId !== 'rejectionStatusFilter'
                        ? 'multiple'
                        : undefined
                    }
                    size="small"
                    options={o.list}
                    value={values[o.categoryId]}
                    onChange={handleFilterChange(o.categoryId)}
                    notFoundContent={t('noResults')}
                    optionFilterProp="children"
                    placeholder={o.category}
                    showArrow
                    aria-label={o.category}
                    data-testid="custom-filter"
                    maxTagCount={0}
                    maxTagPlaceholder={(ommitedValues) =>
                      `${o.category}: ${ommitedValues.length}`
                    }
                  />
                </div>
              );
            })}
            {customFieldsSelectedChoicesFilters.map((o) => (
              <div key={o.categoryId} className={styles.filter}>
                <SelectFilters
                  mode="multiple"
                  size="small"
                  options={o.list}
                  value={values[o.categoryId]}
                  onChange={handleFilterChange(o.categoryId)}
                  notFoundContent={t('noResults')}
                  optionFilterProp="children"
                  placeholder={o.category}
                  showArrow
                  aria-label={o.category}
                  data-testid="custom-filter"
                  maxTagCount={0}
                  maxTagPlaceholder={(ommitedValues) =>
                    `${o.category}: ${ommitedValues.length}`
                  }
                />
              </div>
            ))}
            {customFieldsFilters.map((o) => (
              <div key={o.categoryId} className={styles.filter}>
                {' '}
                <MultiFilterInput
                  size="small"
                  value={values[o.categoryId]}
                  onChange={handleFilterChange(o.categoryId)}
                  optionFilterProp="children"
                  placeholder={o.category}
                  aria-label={o.category}
                  maxTagCount={0}
                  maxTagPlaceholder={(ommitedValues) =>
                    `${o.category}: ${ommitedValues.length}`
                  }
                  data-testid="custom-filter"
                />
              </div>
            ))}
          </div>
        )}
        {hasActiveFilters(values) && (
          <div className={styles.activeFilters} data-testid="active-filters">
            {activeFiltersArray.map(
              (filter) =>
                // display tags only for filters with non-empty values array
                filter.values?.length > 0 && (
                  <ActiveFilterTag
                    filterKey={filter.key}
                    filterValues={filter.values}
                    filterName={filter.name}
                    handleFilterChange={handleFilterChange}
                  />
                ),
            )}
            <Button
              size="small"
              type="text"
              onClick={onClearAllFilters}
              className={styles.clearFiltersButton}
              aria-label="clearFilters"
            >
              {t('CustomFilters-resetFilters')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

CustomFilters.propTypes = {
  filterSets: PropTypes.array,
  values: PropTypes.object,
  onChange: PropTypes.func,
  onClearAllFilters: PropTypes.func,
  areFiltersShown: PropTypes.bool,
  relatedUsers: PropTypes.array.isRequired,
  jobPositions: PropTypes.array.isRequired,
  allStageLists: PropTypes.array.isRequired,
  ratingScales: PropTypes.array.isRequired,
};

export default memo(CustomFilters);
