// See the README for the reference
export const MODULE_CANDIDATE_ADD = 'candidate_add';
export const MODULE_JOB_POSITION_MANAGE = 'job_position_manage';
export const MODULE_LANDING_PAGE_MANAGE = 'landing_page_manage';
export const MODULE_PLAN_PAGE_VIEW = 'plan_page_view';
export const MODULE_EMAIL_SEND = 'email_send';
export const MODULE_BULK_EMAIL_SEND = 'bulk_email_send';
export const MODULE_CHART_REPORT_DOWNLOAD = 'chart_report_download';

export const ALL_ORGANIZATION_MODULES = [
  MODULE_CANDIDATE_ADD,
  MODULE_JOB_POSITION_MANAGE,
  MODULE_LANDING_PAGE_MANAGE,
  MODULE_PLAN_PAGE_VIEW,
  MODULE_EMAIL_SEND,
  MODULE_BULK_EMAIL_SEND,
];
