import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'utils/withRouter';
import { graphql, compose, NetworkStatus } from 'apollo';
import get from 'lodash-es/get';
import { useQuery } from '@apollo/client';

import withOrganizationPk from 'hoc/withOrganizationPk';

import useCandidateDrawer from 'hooks/useCandidateDrawer';
// Disabled due to bug with displaying table data
// import useInfiniteScrollLoader from 'hooks/useInfiniteScrollLoader';

import createFilterInputObject from 'utils/createFilterInputObject.js';

import allStagesQuery from 'pages/candidates/Stage/allStagesQuery.gql';
import billingInformationQuery from 'components/ActivateOrChangePlanButton/billingInformationQuery.gql';

import usersQuery from 'hooks/organizationUsersQuery.gql';
import candidatesQuery from './candidatesQuery.gql';
import jobPositionsQuery from './jobPositionsQuery.gql';
import customFieldsQuery from './customFieldsQuery.gql';
import ratingScalesQuery from './ratingScalesQuery.gql';

import useSearchFilters from './useSearchFilters';

import CandidatesList from './CandidatesList';

const CANDIDATES_PAGE_SIZE = 20;

export const cleanFilter = (filter) =>
  Array.isArray(filter) ? filter.map((o) => (o === 'None' ? null : o)) : null;

function CandidatesListContainer({
  organizationPk,
  navigate,
  location,
  refetch,
  allCandidates,
  candidatesCount,
  isAllCandidatesLoading,
  isFetchMoreLoading,
  isSearchLoading,
  hasNextPage,
  fetchMore,
  relatedUsers,
  jobPositions,
  allStageLists,
  billing,
  quota,
  customFields,
  rejectionReasons,
}) {
  // Disabled due to bug with displaying table data
  // useInfiniteScrollLoader(hasNextPage, isFetchMoreLoading, fetchMore);
  const {
    q,
    filters,
    onSearchInputChange,
    onFiltersChange,
    onClearAllFilters,
  } = useSearchFilters({
    navigate,
    location,
  });

  const {
    isDrawerVisible,
    activeApplicationPk,
    setActiveApplication,
    selectedCandidatePk,
    setSelectedCandidatePk,
    onDrawerClose,
    onNewCandidateFormOpen,
    onCandidateSelected,
    onApplicationClick,
  } = useCandidateDrawer();

  const refetchQuery = useCallback(
    (keepPageSize = 0) => {
      const maxPageSize = Math.max(
        allCandidates.length + keepPageSize,
        CANDIDATES_PAGE_SIZE,
      );
      refetch(keepPageSize ? maxPageSize : CANDIDATES_PAGE_SIZE);
    },
    [allCandidates, refetch],
  );

  const onCandidateDeleted = useCallback(() => {
    onDrawerClose();
    refetchQuery(-1);
  }, [onDrawerClose, refetchQuery]);

  const onNewCandidateCreated = useCallback(
    (newCandidatePk) => {
      refetchQuery(1);
      setSelectedCandidatePk(newCandidatePk);
    },
    [refetchQuery, setSelectedCandidatePk],
  );

  const { data: ratingScalesData } = useQuery(ratingScalesQuery, {
    variables: { organizationPk },
  });

  const ratingScales = ratingScalesData?.viewer?.ratingScales ?? [];

  return (
    <CandidatesList
      q={q}
      filters={filters}
      allCandidates={allCandidates}
      candidatesCount={candidatesCount}
      isAllCandidatesLoading={isAllCandidatesLoading}
      isFetchMoreLoading={isFetchMoreLoading}
      isSearchLoading={isSearchLoading}
      hasNextPage={hasNextPage}
      relatedUsers={relatedUsers}
      jobPositions={jobPositions}
      allStageLists={allStageLists}
      ratingScales={ratingScales}
      rejectionReasons={rejectionReasons}
      billing={billing}
      onSearchInputChange={onSearchInputChange}
      onFiltersChange={onFiltersChange}
      onLoadMoreCandidates={fetchMore}
      isDrawerVisible={isDrawerVisible}
      activeApplicationPk={activeApplicationPk}
      setActiveApplication={setActiveApplication}
      selectedCandidatePk={selectedCandidatePk}
      onDrawerClose={onDrawerClose}
      onCandidateSelected={onCandidateSelected}
      onApplicationClick={onApplicationClick}
      onCandidateDeleted={onCandidateDeleted}
      onNewCandidateFormOpen={onNewCandidateFormOpen}
      onNewCandidateCreated={onNewCandidateCreated}
      onClearAllFilters={onClearAllFilters}
      quota={quota}
      customFields={customFields}
      organizationPk={organizationPk}
    />
  );
}

CandidatesListContainer.propTypes = {
  // From HoCs
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  allCandidates: PropTypes.array,
  candidatesCount: PropTypes.number,
  isAllCandidatesLoading: PropTypes.bool,
  isFetchMoreLoading: PropTypes.bool,
  isSearchLoading: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  fetchMore: PropTypes.func,
  relatedUsers: PropTypes.array,
  jobPositions: PropTypes.array,
  allStageLists: PropTypes.array,
  billing: PropTypes.object,
  quota: PropTypes.object,
  customFields: PropTypes.array,
  rejectionReasons: PropTypes.array,
  organizationPk: PropTypes.string,
};

export default compose(
  withOrganizationPk,
  withRouter,
  graphql(candidatesQuery, {
    options: ({ organizationPk, location }) => ({
      variables: {
        organizationPk,
        pageSize: CANDIDATES_PAGE_SIZE,
        candidateSearchFilterInput: createFilterInputObject({ location }),
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
    }),
    props: ({
      data: { viewer, fetchMore, refetch, networkStatus },
      ownProps: { organizationPk, location },
    }) => ({
      allCandidates: get(viewer, 'allCandidates.edges', []),
      candidatesCount: get(viewer, 'allCandidates.totalCount', 0),
      isAllCandidatesLoading: networkStatus === NetworkStatus.loading,
      isFetchMoreLoading: networkStatus === NetworkStatus.fetchMore,
      isSearchLoading: networkStatus === NetworkStatus.setVariables,
      networkStatus,
      hasNextPage: get(viewer, 'allCandidates.pageInfo.hasNextPage', false),
      fetchMore: () =>
        fetchMore({
          variables: { cursor: viewer.allCandidates.pageInfo.endCursor },
        }),
      refetch: (pageSize) =>
        refetch({
          organizationPk,
          pageSize,
          candidateSearchFilterInput: createFilterInputObject({ location }),
        }),
    }),
  }),
  graphql(usersQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      relatedUsers:
        !loading && viewer
          ? viewer.memberships.edges.map(({ node: { user } }) => user)
          : [],
    }),
  }),
  graphql(jobPositionsQuery, {
    options: ({ organizationPk }) => ({
      variables: { organizationPk },
    }),
    props: ({ data: { viewer } }) => ({
      jobPositions: get(viewer, 'jobPositions.edges', []),
    }),
  }),
  graphql(allStagesQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      allStageLists: !loading && viewer ? viewer.allStageLists : [],
    }),
  }),
  graphql(billingInformationQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      billing: !loading && viewer ? viewer.organizations[0].billing : {},
      quota: !loading && viewer ? viewer.organizations[0].quota : {},
    }),
  }),
  graphql(customFieldsQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      customFields:
        !loading && viewer ? viewer.organizations[0].customFields : [],
    }),
  }),
)(CandidatesListContainer);
