import queryString from 'query-string';
import getCustomFieldsFilters from 'utils/getCustomFieldsFilters';
import getCustomFieldsSelectedChoicesFilter from 'utils/getCustomFieldsSelectedChoicesFilter';

const qs = {
  parse: (o) => queryString.parse(o, { arrayFormat: 'bracket' }),
  stringify: (o) => queryString.stringify(o, { arrayFormat: 'bracket' }),
};

const getFiltersFromUrl = (location) => {
  const relatedUserFilter = qs.parse(location.search).relatedUsers;
  const jobPositionFilter = qs.parse(location.search).position;
  const stageFilter = qs.parse(location.search).stage;
  const ratingFilter = qs.parse(location.search).rating;
  const hasCvFilter = (() => {
    const value = qs.parse(location.search).hasCv;
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return undefined;
  })();
  const q = qs.parse(location.search).q;
  const customFieldsFilters = getCustomFieldsFilters(qs.parse(location.search));
  const customFieldsSelectedChoicesFilters =
    getCustomFieldsSelectedChoicesFilter(qs.parse(location.search));
  const rejectionStatusFilter = qs.parse(location.search).rejectionStatus;

  return {
    q,
    relatedUserFilter,
    jobPositionFilter,
    stageFilter,
    ratingFilter,
    hasCvFilter,
    customFieldsFilters,
    customFieldsSelectedChoicesFilters,
    rejectionStatusFilter,
  };
};

export default getFiltersFromUrl;
