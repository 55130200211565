import { useQuery } from '@apollo/client';
import useMembership from './useMembership';
import usersQuery from './organizationUsersQuery.gql';

export default function useOrganizationUsers() {
  // Getting current user's organizationPk
  const [membership] = useMembership();
  const organizationPk = membership ? membership.organization.pk : '';
  const { data, loading } = useQuery(usersQuery, {
    variables: { organizationPk },
  });

  if (loading || !data) {
    return [];
  }

  const {
    viewer: {
      memberships: { edges },
    },
  } = data;

  return edges.map((edge) => edge.node);
}
